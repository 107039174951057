import React from "react";
import { motion } from "framer-motion";
import ResumeCard from "./ResumeCard";

const Education = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.5 } }}
      className="w-full flex flex-col lgl:flex-row gap-10 lgl:gap-20"
    >
      {/* part one */}
      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2012 - 2017</p>
          <h2 className="text-3xl md:text-4xl font-bold">Education </h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
        <ResumeCard
            title="MSc in Computer Science"
            subTitle="Abasaheb Garware College (2015 - 2017)"
            result="8.5/10"
            des="Higher education is tertiary education leading to award of an academic degree. Higher education, also called post-secondary education."
          />
          <ResumeCard
            title="BSc in Computer Science"
            subTitle="Savitribai Phule Pune University (2012 - 2015)"
            result="7.2/10"
            des="The training provided by universities in order to prepare people to work in various sectors of the economy or areas of culture."
          /> 
     
        </div>
      </div>
      {/* part Two */}

      <div>
        <div className="py-6 lgl:py-12 font-titleFont flex flex-col gap-4">
          <p className="text-sm text-designColor tracking-[4px]">2017 - Current</p>
          <h2 className="text-3xl md:text-4xl font-bold">Job Experience</h2>
        </div>
        <div className="mt-6 lgl:mt-14 w-full h-[1000px] border-l-[6px] border-l-black border-opacity-30 flex flex-col gap-10">
          <ResumeCard
            title="Sr. Software Engineer"
            subTitle="Globant (2021 - Present)"
            result="INDIA"
            des="Globant, founded in 2003 and headquartered in Luxembourg, is a global digital transformation company. With expertise in software solutions, AI, cloud computing, and UX design, it serves diverse industries worldwide. Known for innovation and talent development, Globant fosters a collaborative culture to deliver cutting-edge solutions and meaningful user experiences."
          />
          <ResumeCard
            title="Software Engineer"
            subTitle="Hansen Technologies - (2019 - 2021)"
            result="INDIA"
            des="A popular destination with a growing number of highly qualified homegrown graduates, it's true that securing a role in Malaysia isn't easy."
          />
          <ResumeCard
            title="Software Engineer"
            subTitle="Angular Minds - (2017 - 2019)"
            result="INDIA"
            des="The Oman economy has grown strongly over recent years, having transformed itself from a producer and innovation-based economy."
          />
        </div>
      </div>
    </motion.div>
  );
};

export default Education;
